import Cookies from "js-cookie";
import axios from "axios";
import conf from "@/config";
import http from "./http";

import img200x200 from "@/assets/images/noimg/200x200.jpg";
import img240x300 from "@/assets/images/noimg/240x300.jpg";
import img280x197 from "@/assets/images/noimg/280x197.jpg";
import img324x200 from "@/assets/images/noimg/324x200.jpg";
import img324x406 from "@/assets/images/noimg/324x406.jpg";
import img334x220 from "@/assets/images/noimg/334x220.jpg";
import img334x470 from "@/assets/images/noimg/334x470.jpg";
import img335x164 from "@/assets/images/noimg/335x164.jpg";
import img704x280 from "@/assets/images/noimg/704x280.jpg";
import defImg from "@/assets/images/img/default-headimg.jpg";

/**
 * @author: wangjing
 * @update: 20191114
 * @func
 * @description: 跳转授权认证方法
 * @param {String} u 重定向url
 */
export const authFun = u => {
  window.location.replace(
    conf.apiURL + `/api/weixin/auth?redirectUrl=${encodeURIComponent(u)}`
  );
};


/**
 * @author: wuyifan
 * @update: 20201215
 * @func
 * @description: 判断商品图片，优先选择imgs的图片，如果没有就去thumbnail的图片
 * @param {String} u 参数
 */
export const getGoodsImg = u => {
  let img = '';
  // 商品缩略图
  let thumbnail = JSON.parse(u.thumbnail);
  // 商品规格图片
  let imgs = u.specList ? JSON.parse(u.specList[0].imgs) :JSON.parse(u.imgs);
  if (imgs.length == 0) {
    if(thumbnail[0]){
      img = thumbnail[0].url
    }
  } else {
    if(imgs[0]){
      img = imgs[0].url
    }
  }
  return requestImgUrl(img)
};

/**
 * @author: wuyifan
 * @update: 20201215
 * @func
 * @description: 判断商品图片没有，显示默认图片
 * @param {String} u 参数
 */
export const noImgSet = u => {
  let img = defImg;
  if (u != '[]') {
    if(JSON.parse(u)[0]){
      img = requestImgUrl(JSON.parse(u)[0].url);
    }
  }
  return img;
};
/**
 * @author: wuyifan
 * @update: 20201215
 * @func
 * @description: 计算限时秒杀到现在结束相差多少
 * @param {String} u 
 */
export const CountDown = end => {
  let v = {};
  let hour = parseInt(end / 3600);
  let minute = parseInt(end / 60 % 60);
  let second = parseInt(end % 60);
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minute >= 0 && minute <= 9) {
    minute = "0" + minute;
  }
  if (second >= 0 && second <= 9) {
    second = "0" + second;
  }
  v.minute = minute;
  v.second = second;
  v.hour = hour;
  return v;

};




/**
 * @author: WUYIFAN
 * @update: 20191120
 * @func
 * @description: 跳转授权返回 code = noAuthConfig
 * @param {String} code 重定向code
 */
export const noAuthConfig = code => {
  if (sessionStorage.getItem("noAuthConfig")) {
    return true;
  } else {
    return false;
  }
};

/**
 * @author: WUYIFAN
 * @update: 20191125
 * @func
 * @description: 打开分享页
 * @param {String} referrerUserId 分享出来的用户ID 必填
 * @param {String} srcId 业务ID或标识（用来记录唯一性），如没有业务内容（分享类型为0时），可标识为“index”之类的页面标识 必填
 * @param {String} srcTitle 业务标题（可空），当分享类型为0时必填，否则不做记录
 * @param {String} type 分享类型; 0=其它(默认), 1=活动, 2=比赛, 3=任务
 */
export const shareOpne = data => {
  http.post("/user/share/open", data).then(res => {
    console.log(res);
  });
};

/**
 * @author: WUYIFAN
 * @update: 20191125
 * @func
 * @description: 判断页面是否有referrerUserId，如果有就替换，没有就添加
 * @param {String} referrerUserId 分享出来的用户ID 必填

 */
export const isUrlParam = data => {
  var userId = Cookies.get("user") ? JSON.parse(Cookies.get("user")).userId : '';
  let url = location.href.split("#")[0];
  if (getUrlParam(data.getUrlParam)) {
    url = location.origin+funcUrlDel(data.getUrlParam);
    return url;
  }

  return url;

  function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]); return null; //返回参数值
  }
};



/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 设置cook
 * @param {String} k cook名称
 * @param {object} [v] cook值
 */
export const setCookies = (v, k) => {
  let ke = "user";
  if (k) {
    ke = k;
  }
  Cookies.set(ke, v, { expires: 30 });
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 获取图片真实地址
 * @param {string} url 图片地址
 * @param {boolean} privates 是否私有
 */
export const realImgUrl = (url, privates) => {
  if (!url || url === "undefined") {
    return '';
  }
  const token = Cookies.get("user")
    ? JSON.parse(Cookies.get("user")).token
    : "";

  let sp = "/";
  if (url.indexOf("/") === 0) {
    sp = "";
  }

  let imgurl = "";
  let u = "";
  if (privates) {
    u = `${sp}${url}&__token__=${token}`;
  } else {
    u = `${sp}${url}`;
  }
  imgurl = `${conf.apiURL}/api/oss/redirect?file=${encodeURIComponent(u)}`;

  return imgurl;
};


/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 图片获取地址
 * @param {string} url 图片地址
 * @param {boolean} privates 是否私有
 */
export const requestImgUrl = (url, privates) => {
  if (!url || url === "undefined") {
    return '';
  }
  const token = (Cookies.get("user") && Cookies.get("user") !== 'undefined')
    ? JSON.parse(Cookies.get("user")).token
    : "";

  let sp = "/";
  if (url.indexOf("/") === 0) {
    sp = "";
  }

  let imgurl = "";
  let u = "";
  if (privates) {
    u = `${sp}${url}&__token__=${token}`;
  } else {
    u = `${sp}${url}`;
  }
  imgurl = `${conf.apiURL}/api/oss/redirect?file=${encodeURIComponent(u)}`;

  return imgurl;
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 上传视频文件
 * @param {object}} obj 图片地址
 * @param {file} obj.file 文件
 * @param {func} obj.cb 上传接口执行完成回调函数
 * @param {func} obj.errcb 上传失败回调函数
 * @param {number} obj.uploadRate 已上传的比例
 * @param {func} obj.procb 上传成功回调函数
 * @param {boolean} privates 是否私有
 */
export const uploadFile = obj => {
  http
    .get(`/oss/token/uploading${obj.isPrivate ? "" : "?isPrivate=false"}`)
    .then(res => {
      if (!res) {
        return;
      }
      const v = res.data;
      const uptoken = v.uptoken;
      let formData = new FormData();
      for (let k in v) {
        formData.append(k, v[k]);
      }

      formData.append("file", obj.file);
      if (uptoken) {
        formData.append("token", uptoken);
      }

      // 上传视频进度
      axios({
        method: "POST",
        url: v.action,
        // headers: {
        //   "Content-Type": "multipart/form-data;charset=UTF-8"
        // },
        data: formData,
        onUploadProgress: function(e) {
          //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          //如果lengthComputable为false，就获取不到e.total和e.loaded
          if (e.lengthComputable) {
            var rate = (obj.uploadRate = e.loaded / e.total); //已上传的比例
            if (rate <= 1) {
              //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
              //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
              //等响应回来时，再将进度设为100%
              if (obj.procb) {
                obj.procb(rate);
              }
            }
          }
        }
      })
        .then(res => {
          if (obj.cb) {
            obj.cb(res.data);
          }
        })
        .catch(() => {
          if (obj.errcb) {
            obj.errcb();
          }
        });
    });
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 上传图片
 * @param {file} file 文件
 * @param {func} cb 上传接口执行完成回调函数
 * @param {boolean} privates 是否私有
 */
export const requestFileName = (file, cb, isPrivate, fileName) => {
  http
    .get(`/oss/token/uploading${isPrivate ? "" : "?isPrivate=false"}`)
    .then(res => {
      if (!res) {
        return;
      }
      const v = res.data;
      const uptoken = v.uptoken;
      const formData = new FormData();
      for (let k in v) {
        formData.append(k, v[k]);
      }

      formData.append("file", file);
      if (fileName) {
        formData.append("fileName", fileName);
      }
      // if (uptoken) {
      //   formData.append("token", uptoken);
      // }

      http.post(v.action, formData).then(re => {
        if (!re) {
          return;
        }
        cb(re);
      });
    });
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断是否为微信客户端
 */
export const isWeiXin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  let b = false;
  if (
    ua.match(/MicroMessenger/i) !== null &&
    ua.match(/MicroMessenger/i)[0] === "micromessenger"
  ) {
    b = true;
  }
  return b;
};


/**
 * @author: wuyifan
 * @update: 20201216
 * @func
 * @description: 生成随机id
 */
export const RandomID = () => {
  let idStr = Date.now().toString(36);
  idStr += Math.random().toString(36).substr(3)
  return idStr
};

/**
 * @author: wuyifan
 * @update: 20201217
 * @func
 * @description: 记录购物车数量
 */
export const countCartNum = (num) => {
  let cartNum = 0;
  if (num) {
    cartNum = localStorage.setItem('cartNum', num);
  } else if (localStorage.getItem('cartNum')) {
    cartNum = localStorage.getItem('cartNum');
  }
  return cartNum;
};

export const phoneShock = () => {
  navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
  let b = false;
  if ( navigator.vibrate ) {
    b = true
  }
  return b;
}

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 微信支付
 * @param {object} v 公众号名称签名对象参数
 * @param {func} cbsuccess 支付成功回调函数
 * @param {func} cbfail 支付失败回调函数
 */
export const onBridgeReady = (v, cbsuccess, cbfail) => {
  // alert(JSON.stringify((v)));
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: v.appId, // 公众号名称，由商户传入
      timeStamp: v.timeStamp, // 时间戳，自1970年以来的秒数
      nonceStr: v.nonceStr, // 随机串
      package: v.package, // 订单详情扩展字符串
      signType: v.signType, // 微信签名方式
      paySign: v.paySign // 微信签名
    },
    res => {
      // alert("res=" + JSON.stringify(res));
      // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // console.log("支付成功");
        if (cbsuccess) cbsuccess();
        //支付成功后跳转的页面
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        if (cbfail) cbfail();
        // console.log("支付取消");
      } else if (res.err_msg == "get_brand_wcpay_request:fail") {
        // console.log("支付失败");
        WeixinJSBridge.call("closeWindow");
        if (cbfail) cbfail();
      }
    }
  );
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 微信支付-调用
 * @param {object} v 公众号名称签名对象参数
 * @param {func} cbsuccess 支付成功回调函数
 * @param {func} cbfail 支付失败回调函数
 */
export const onBridgeReadyStart = (v, cbsuccess, cbfail) => {
  if (typeof WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener(
        "WeixinJSBridgeReady",
        onBridgeReady(v, cbsuccess, cbfail),
        false
      );
    } else if (document.attachEvent) {
      document.attachEvent(
        "WeixinJSBridgeReady",
        onBridgeReady(v, cbsuccess, cbfail)
      );
      document.attachEvent(
        "onWeixinJSBridgeReady",
        onBridgeReady(v, cbsuccess, cbfail)
      );
    }
  } else {
    onBridgeReady(v, cbsuccess, cbfail);
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 返回中文性别
 * @param {number} v 性别参数
 * @returns {string}
 */
export const isGender = v => {
  if (v === 1) {
    return "男";
  } else if (v === 2) {
    return "女";
  } else {
    return "";
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 返回中文选手状态
 * @param {number} v 0-进行中，1-晋级，2-淘汰，为空表示未加入该赛程
 * @returns {string}
 */
export const getPlayerStatus = v => {
  if (v === 0) {
    return "进行中";
  } else if (v === 1) {
    return "晋级";
  } else if (v === 2) {
    return "淘汰";
  } else {
    return "";
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 返回赛事阶段状态中文
 * @param {number} v 0-未开始，1-已开始，2-已结束
 * @returns {string}
 */
export const getMatchStageStatus = v => {
  if (v === 0) {
    return "未开始";
  } else if (v === 1) {
    return "进行中";
  } else if (v === 2) {
    return "已结束";
  } else {
    return "";
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 返回赛事状态状态中文
 * @param {number} v 1-进行中，2-已结束，3-已取消
 * @returns {string}
 */
export const getMatchStatus = v => {
  if (v === 1) {
    return "进行中";
  } else if (v === 2) {
    return "已结束";
  } else if (v === 3) {
    return "已取消";
  } else {
    return "";
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 去掉路由指定参数——全局路由时用
 * @param {number} name 路由参数名称，可以是单个参数，也可以是以逗号分隔的多个参数
 * @returns {string} 去掉指定参数后的url
 */
export const funcUrlDel = name => {
  const arrName = name.split(",");
  var loca = window.location;
  var query = loca.search.substr(1);

  let obj = {};
  let arr = query.split("&");
  let url = "";
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].split("=");
    obj[arr[i][0]] = arr[i][1];

    if (i === arr.length - 1) {
      for (let k = 0; k < arrName.length; k++) {
        if (query.indexOf(arrName[k]) > -1) {
          delete obj[arrName[k]];
        }
      }
    }
  }

  var baseUrl =
    JSON.stringify(obj) === "{}" ? loca.pathname : loca.pathname + "?";
  url =
    baseUrl +
    JSON.stringify(obj)
      .replace(/[\"\{\}]/g, "")
      .replace(/\:/g, "=")
      .replace(/\,/g, "&");
  return url;
};

/**
 * @author: wuyifan
 * @update: 20191129
 * @func
 * @description: 日期格式化
 * @param {number} date  当前时间
 * @returns {string} 格式化后的时间
 */
export const DateFormat = date => {
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
};


/**
 * @author: wuyifan
 * @update: 20200424
 * @func
 * @description: 回车符号处理
 */
export const SymbolProcess = str1 => {
  var str = str1.replace(/\n/,"<br/>");
  return str;
};


/**
 * @author: wangjing
 * @update: 20200924
 * @func
 * @description: 默认图尺寸
 * @param {String} v 图片url
 * @param {String} w 图片尺寸
 */


// import img200x200 from "@/assets/images/img/200x200.jpg";
// import img240x300 from "@/assets/images/img/240x300.jpg";
// import img280x197 from "@/assets/images/img/280x197.jpg";
// import img324x200 from "@/assets/images/img/324x200.jpg";
// import img324x406 from "@/assets/images/img/324x406.jpg";
// import img334x220 from "@/assets/images/img/334x220.jpg";
// import img334x470 from "@/assets/images/img/334x470.jpg";
// import img335x164 from "@/assets/images/img/335x164.jpg";
// import img704x280 from "@/assets/images/img/704x280.jpg";
export const setDefaultImg = (v,w) => {
  let url = "";
  if (v && v !== '' && w === '200x200') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_200,w_200";
  } else if (w === '200x200') {
    url = img200x200;
  } else if (v && v !== '' && w === '240x300') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_300,w_240";
  } else if (w === '240x300') {
    url = img240x300;
  } else if (v && v !== '' && w === '280x197') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_197,w_280";
  } else if (w === '280x197') {
    url = img280x197;
  } else if (v && v !== '' && w === '324x200') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_200,w_324";
  } else if (w === '324x200') {
    url = img324x200;
  } else if (v && v !== '' && w === '324x406') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_406,w_324";
  } else if (w === '324x406') {
    url = img324x406;
  } else if (v && v !== '' && w === '334x220') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_220,w_334";
  } else if (w === '334x220') {
    url = img334x220;
  } else if (v && v !== '' && w === '334x470') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_470,w_334";
  } else if (w === '334x470') {
    url = img334x470;
  } else if (v && v !== '' && w === '335x164') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_164,w_335";
  } else if (w === '335x164') {
    url = img335x164;
  } else if (v && v !== '' && w === '704x280') {
    url = requestImgUrl(v) + "?x-oss-process=image/resize,m_fill,h_280,w_704";
  } else if (w === '704x280') {
    url = img704x280;
  }
  return url;
};

