import http from "@/utils/http";

export default {
  namespaced: true,
  state: {
  },
  mutations: {},
  getters: {},
  actions: {
    // 订单售后查询
    async getOrderAfter({ commit }, data) {
      const result = await http.get("/mall/commodity/saleService/order", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    async getOrderPurchaseList({ commit }, data) {
      const result = await http.get("/mall/commodity/order/purchaseList", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    async getOrderDepartmentList({ commit }, data) {
      const result = await http.get("/mall/commodity/order/departmentList", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    async getOrderSupplierList({ commit }, data) {
      const result = await http.get("/mall/commodity/order/supplierList", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 订单相关-查询推广用户有效订单商品详情
    async getTgOrderDes({ commit }, data) {
      const result = await http.get("/mall/commodity/order/commodity", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    
    async getTSaleServicePage({ commit }, data) {
      const result = await http.get("/mall/commodity/saleService/page", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    async getPromoted({ commit }, data) {
      const result = await http.get("/mall/vip/promoted/department", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    postSaleServiceCancel(context, data) {
      return http.post('/mall/saleService/cancel', data)
    },
    postSaleServiceApply(context, data) {
      return http.post('/mall/saleService/apply', data)
    },
    // 推广-给下级用户赠送余额
    postPromotedBalance(context, data) {
      return http.post('/mall/vip/promoted/balance', data)
    },
    // 获取余额充值链接
    postBalancePay(context, data) {
      return http.post('/mall/commodity/balance/pay', data)
    },
    // 获取余额充值状态
    async getBalancePay({ commit }, data) {
      const result = await http.get("/mall/commodity/balance/pay", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 确认收货
    PutConfirmreceipt(context, data) {
      return http.put('/mall/commodity/order/confirm', data)
    },
    // 找回密码、修改密码
    modifyPwd(context, data) {
      return http.put("/user/password", data);
    },
    // 修改手机号
    modifyPhone(context, data) {
      return http.put("/user/phone", data);
    },
    // 绑定手机号码
    bindPhone(context, data) {
      return http.put("/user/phone/bind", data);
    },
    // 交易记录查询（居民积分记录）
    async getTransactionList({ commit }, data) {
      const result = await http.get("/user/transaction/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    
    // 查询用户会员等级
    async getDepositList({ commit }, data) {
      const result = await http.get("/mall/commodity/order/deposit", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 查询用户会员等级
    async getVipLevel({ commit }, data) {
      const result = await http.get("/mall/vipLevel", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },

    // 推广列表
    async promotion({ commit }, data) {
      const result = await http.get("/mall/vip/promoted/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },

    // 推广-查看下级用户的订单列表
    async promotedOrderPage({ commit }, data) {
      const result = await http.get("/mall/vip/promoted/order/page", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },

    // 会员-查询用户推广统计-总/月/日
    async promotionSort({ commit }, data) {
      const result = await http.get("/mall/vip/promoted/statistics/sort", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },

    // 短信验证码校验
    async getValidate({ commit }, data) {
      const result = await http.get("/edge/sms/validate", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 用户优惠券数量、余额
    async userCouponsCount({ commit }, data) {
      const result = await http.get(`/mall/coupons/count`);
      if (!result) {
        return;
      }
      return result;
    },
    // 我的足迹商品分页列表
    async getFootprint({ commit }, data) {
      const result = await http.get(`/mall/commodity/footprint`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    //用户信息查询
    async getUserInfo({ commit }, data) {
      const result = await http.get(`/user/info`);
      if (!result) {
        return;
      }
      return result;
    },
    // 订单分页
    async getMyOrder({ commit }, data) {
      const result = await http.get(`/mall/commodity/order/page`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 订单详情
    async getOrderDetails({ commit }, data) {
      console.log(data);
      const result = await http.get(`/mall/commodity/order/detail/${data.id}`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 用户信息修改
    modifyUserInfo(context, data) {
      return http.put("/user/info", data);
    },
    // 申请退款
    PutApplyRefund(context, data) {
      return http.put("/mall/commodity/order/refund", data);
    },
    // 取消订单
    PutCancelOrder(context, data) {
      return http.put("/mall/commodity/order/cancel", data);
    },
    // 订单统计
    async getOrderSts({ commit }, data) {
      const result = await http.get(`/mall/commodity/order/statistics`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 提交反馈
    PostFeedback(context, data) {
      return http.post("/mall/feedback", data);
    },
    // 我的收藏商品分页列表
    async getMyCollect({ commit }, data) {
      const result = await http.get(`/mall/commodity/collect`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 收藏-清空
    PostClearCollect(context, data) {
      return http.post("/collect/clear", data);
    },
    // 收藏-取消
    PostCancelCollect(context, data) {
      return http.post("/collect/cancel", data);
    },
    // 足迹-删除
    PostDelFootprint(context, data) {
      return http.post("/footprint/cancel", data);
    },
    // 平台优惠券列表
    async getPtCoupons({ commit }, data) {
      const result = await http.get(`/mall/coupons/template/effectiveList`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 用户优惠券列表
    async getMyCoupons({ commit }, data) {
      console.log(data);
      const result = await http.get(`/mall/coupons/list`, {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 用户领取优惠券
    PostCoupons(context, data) {
      return http.post(`/mall/coupons/${data.id}`, data);
    },
  }
};
