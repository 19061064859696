import http from "@/utils/http";

export default {
  namespaced: true,
  state: {
  },
  mutations: {},
  getters: {},
  actions: {
    // 首页标签接口
    async getTagsSp({ commit }, data) {
      const result = await http.get("/public/tags/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 热门搜索
    async getHotSearch({ commit }, data) {
      const result = await http.get("/public/mall/commodity/hotSearch", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    
    // 再来一单
    getOrderInfo(context, data) {
      return http.post("/mall/commodity/order/cart", data);
    },
    // 服务承诺列表
    async getDetailsPromise({ commit }, data) {
      const result = await http.get("/public/mall/commodity/promise", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 用户添加商品到货提醒
    AddCommodityNotice(context, data) {
      return http.post("/mall/commodity/arrival/notice", data);
    },
    // 添加购物车
    AddCommodityCart(context, data) {
      return http.post("/mall/commodity/cart", data);
    },
    // 商品下单
    placeOrder(context, data) {
      return http.post("/mall/commodity/order", data);
    },
    // 获取订单支付链接
    postOrderLink(context, data) {
      return http.post("/mall/commodity/order/pay", data);
    },
    // 提交求购表单
    postMallRequire(context, data) {
      return http.post("/mall/require", data);
    },
    // 获取订单支付状态
    async getOrderPayType({ commit }, data) {
      const result = await http.get("/mall/commodity/order/pay", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 删除购物车-删除商品
    async DeleteCommodity({ commit }, data) {
      const result = await http.delete("/mall/commodity/cart", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    //购物车-批量删除商品
    async DeleteCarBatch({ commit }, data) {
      const result = await http.delete("/mall/commodity/cart/batch", {data: data});
      if (!result) {
        return;
      }
      return result;
    },
    // 购物车-总价计算
    CountAmount(context, data) {
      return http.post("/mall/commodity/cart/amount", data);
    },
    // 购物车-列表
    async getCommodityCart({ commit }, data) {
      const result = await http.get("/mall/commodity/cart", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 购物车-商品数量
    async getCommodityCount({ commit }, data) {
      const result = await http.get("/mall/commodity/cart/count", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 商品分类
    async CommodityClass({ commit }, data) {
      const result = await http.get("/mall/commodity/category/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 秒杀活动列表
    async GetSeckillList({ commit }, data) {
      const result = await http.get("/mall/commodity/seckill/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 秒杀活动商品规格列表
    async GetSeckillSpecList({ commit }, data) {
      const result = await http.get("/mall/commodity/seckill/spec/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 获取商品分页列表
    async GetCommodityList({ commit }, data) {
      const result = await http.get("/mall/commodity/page", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 获取商品详情
    async GetCommodityDetail({ commit }, data) {
      const result = await http.get("/mall/commodity/detail", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
  }
};
