// 个人中心
const BuyLimit = resolve => {
  import("@/views/activity/BuyLimit").then(module => {
    resolve(module);
  });
};

// 秒杀
const Seckill = resolve => {
  import("@/views/activity/Seckill").then(module => {
    resolve(module);
  });
};
// 文章详情 
const ArticleDetails = resolve => {
  import("@/views/activity/ArticleDetails").then(module => {
    resolve(module);
  });
};
export default [
  {
    path: "",
    name: "Account",
    redirect: "home"
  },
  {
    path: "buylimit",
    name: "buylimit",
    component: BuyLimit,
    meta: {
      title: "限时抢购",
    }
  },
  {
    path: "articledetails",
    name: "articledetails",
    component: ArticleDetails,
    meta: {
      title: "文章详情",
    }
  },
  {
    path: "seckill",
    name: "seckill",
    component: Seckill,
    meta: {
      title: "限时秒杀",
    }
  },
]