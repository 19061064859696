import http from "@/utils/http";

export default {
  namespaced: true,
  state: {
  },
  mutations: {},
  getters: {},
  actions: {
    
  }
};
