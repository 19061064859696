<template>
  <div id="app" class="h5w">
    <transition name="fade">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>
    <transition name="fade">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>

    <div class="fanhui" @click="fanhui" v-if="isFh">
      <i class="mintui mintui-back"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFh: false,
    }
  },
  methods: {
    fanhui() {
      this.$router.go(-1)
    },
  },
  watch: {
    '$route'(e) {
      if (e.path === '/' || e.path === '/shopping/home' || e.path === '/shopping/jcorder' || e.path === '/shopping/car' || e.path === '/account/home') {
        this.isFh = false;
      } else {
        this.isFh = true;
      }
    },
  },
  mounted() {
    if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
    ) {
      handleFontSize();
    } else {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
        //IE浏览器，非W3C规范
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", function() {
        WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      });
    }


    //禁止鼠标右击
      // document.oncontextmenu = function() {
      //   event.returnValue = false;
      // };
      // //禁用开发者工具F12
      // document.onkeydown = document.onkeyup = document.onkeypress = function(event) {
      //   let e = event || window.event || arguments.callee.caller.arguments[0];
      //   if (e && e.keyCode == 123) {
      //     e.returnValue = false;
      //     return false;
      //   }
      // };
      // let userAgent = navigator.userAgent;
      // if (userAgent.indexOf("Firefox") > -1) {
      //   let checkStatus;
      //   let devtools = /./;
      //   devtools.toString = function() {
      //     checkStatus = "on";
      //   };
      //   setInterval(function() {
      //     checkStatus = "off";
      //     console.log(devtools);
      //     console.log(checkStatus);
      //     console.clear();
      //     if (checkStatus === "on") {
      //       let target = "";
      //       try {
      //         window.open("about:blank", (target = "_self"));
      //       } catch (err) {
      //         let a = document.createElement("button");
      //         a.onclick = function() {
      //           window.open("about:blank", (target = "_self"));
      //         };
      //         a.click();
      //       }
      //     }
      //   }, 200);
      // } else {
      //   //禁用控制台
      //   let ConsoleManager = {
      //     onOpen: function() {
      //       alert("Console is opened");
      //     },
      //     onClose: function() {
      //       alert("Console is closed");
      //     },
      //     init: function() {
      //       let self = this;
      //       let x = document.createElement("div");
      //       let isOpening = false,
      //         isOpened = false;
      //       Object.defineProperty(x, "id", {
      //         get: function() {
      //           if (!isOpening) {
      //             self.onOpen();
      //             isOpening = true;
      //           }
      //           isOpened = true;
      //           return true;
      //         }
      //       });
      //       // setInterval(function() {
      //       //   isOpened = false;
      //       //   console.info(x);
      //       //   console.clear();
      //       //   if (!isOpened && isOpening) {
      //       //     self.onClose();
      //       //     isOpening = false;
      //       //   }
      //       // }, 200);
      //     }
      //   };
      //   ConsoleManager.onOpen = function() {
      //     //打开控制台，跳转
      //     let target = "";
      //     try {
      //       window.open("about:blank", (target = "_self"));
      //     } catch (err) {
      //       let a = document.createElement("button");
      //       a.onclick = function() {
      //         window.open("about:blank", (target = "_self"));
      //       };
      //       a.click();
      //     }
      //   };
      //   ConsoleManager.onClose = function() {
      //     alert("Console is closed!!!!!");
      //   };
      //   ConsoleManager.init();
      // }



  },
};
</script>
<style scoped>
.fanhui{
  position: fixed;
  left: .4rem;
  bottom: 15%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #e1e1e1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
