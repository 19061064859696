/**
 * 公共的util逻辑
 */

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 日期格式化
 * @param {date} v
 * @returns {string}
 */
Date.prototype.format = function(fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 日期格式化
 * @param {date} v
 * @returns {string}
 */
export function formatDate(date, ftm) {
  const d = new Date(date);
  let datetime = "";
  let dateNmuber = "";

  if (d.getDate() < 10) {
    dateNmuber = `0${d.getDate()}`;
  } else {
    dateNmuber = d.getDate();
  }
  if (d.getMonth() + 1 < 10) {
    datetime = d.getFullYear() + "-0" + (d.getMonth() + 1) + "-" + dateNmuber;
  } else {
    datetime = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + dateNmuber;
  }
  return datetime;
}

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断是否外链
 * @param {string} v
 * @returns {boolean}
 */
export const isHttp = v => {
  return v.indexOf("http") != -1;
};

/**
 * @author: 吴一帆
 * @update: 20191030
 * @func
 * @description: 价格小于0显示价格认证后可见
 * @param {string} v
 * @returns {string}
 */

export const isCertified = v => {
  let txt = '价格认证后可见'
  return txt;
};

export const isTel = v => {
  return v.indexOf("tel") != -1;
};


/**
 * @author: wuyifan
 * @update: 20191204
 * @func
 * @description: 判断是否带有http
 * @param {string} v
 * @returns {boolean}
 */
export const isHttp2 = v => {
  if (!v) {
    return false;
  }
  return v.indexOf("http") != -1;
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断是否有方法名
 * @param {string} v
 * @returns {boolean}
 */
export const isFun = v => {
  const arr = v.replace("javascript:", "").replace("()", "");
  return [v.indexOf("javascript") != -1, arr];
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断正整数
 * @param {string} v
 * @returns {boolean}
 */
export const isInt = v => {
  const reg = /^\+?[1-9][0-9]*$/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断邮箱
 * @param {string} v
 * @returns {boolean}
 */
export const isEmail = v => {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断密码
 * @param {string} v
 * @returns {boolean}
 */
export const isPassword = v => {
  const reg = /^[0-9A-Za-z]{6,16}$/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description:  判断电话号码格式
 * @param {string} v
 * @returns {boolean}
 */
export const isPhone = v => {
  const reg = /^((13[0-9])|(14[5|7])|(15([0-9]))|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/g;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description:  6位数的验证码
 * @param {string} v
 * @returns {boolean}
 */
export const isCode = v => {
  const reg = /^\d{6}$/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description:  字母数字用户名
 * @param {string} v
 * @returns {boolean}
 */
export const isLoginName = v => {
  const reg = /^[0-9a-zA-Z]{2,15}$/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description:  中文用户名
 * @param {string} v
 * @returns {boolean}
 */
export const isZhName = v => {
  const reg = /^[\u4e00-\u9fa5]{2,10}$/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断身份证号
 * @param {string} v
 * @returns {boolean}
 */
export const isIdCard = v => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(v);
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断是否为微信客户端
 */
export const isWeiXin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  let b = false;
  if (
    ua.match(/MicroMessenger/i) !== null &&
    ua.match(/MicroMessenger/i)[0] === "micromessenger"
  ) {
    b = true;
  }
  return b;
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 数字格式优化（199，199,199）
 * @param {number} str 需要转化的数组
 * @returns {string}
 */
export const formatNum = str => {
  let newStr = "";
  let count = 0;
  if (typeof str == undefined) {
    return false;
  }
  if (typeof str == Number) {
    str = str.toString();
  }
  if (str.indexOf(".") == -1) {
    for (let i = str.length - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + "," + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr; //自动补小数点后两位
    return str;
  } else {
    for (let i = str.indexOf(".") - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + "," + newStr;
      } else {
        newStr = str.charAt(i) + newStr; //逐个字符相接起来
      }
      count++;
    }
    str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
    return str;
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 判断是否为图片
 * @param {string} v
 * @returns {boolean}
 */
export const isIMG = v => {
  if (!v) {
    return false;
  }
  if (
    v.indexOf("jpg", v.length - 4) > 0 ||
    v.indexOf("png", v.length - 4) > 0
  ) {
    return true;
  }
  return false;
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: ios键盘弹窗收缩时，页面回滚
 */
export const fixScroll = () => {
  let u = navigator.userAgent;
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isiOS) {
    window.scrollTo(0, 0);
  }
};

/**
 * @author: 吴一帆
 * @update: 20201221
 * @func
 * @description: 订单状态
 */
export const orderStatus = (e) => {
  // 订单状态; 100=已下单，200=待发货（已支付），201=已发货，202=确认收货，300=已取消，400=申请退款，401=退款驳回，402=退款中，403=退款成功，404=退款失败，500=已完成
  // 支付状态; 0=待支付，1=支付成功，2=支付失败，3=支付中
  let t = "";
  if (e == 100) {
    t = "待支付"
  } else if (e == 200) {
    t = "待发货";
  } else if (e == 201) {
    t = "已发货";
  } else if (e == 202) {
    t = "确认收货";
  } else if (e == 300) {
    t = "已取消";
  } else if (e == 400) {
    t = "申请退款";
  } else if (e == 401) {
    t = "退款驳回";
  } else if (e == 402) {
    t = "退款中";
  } else if (e == 403) {
    t = "退款成功";
  } else if (e == 404) {
    t = "退款失败";
  }
  return t;
};