import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { RandomID, isWeiXin, setCookies, isUrlParam, funcUrlDel, authFun } from "@/utils/common";
import http from "@/utils/http";
Vue.use(VueRouter)
// 容器
const AccountContainer = () => import("@/views/account/Index");
const ShoppingContainer = () => import("@/views/shopping/Index");
const GuideContainer = () => import("@/views/guide/Index");
const ActivityContainer = () => import("@/views/activity/Index");


// 二级路由
import account from "@/router/account";
import shopping from "@/router/shopping";
// 活动
import activity from "@/router/activity";
import guide from "@/router/guide";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title:"首页",
      keepAlive: true
    },
  },
  {
    path: "/account",
    component: AccountContainer,
    children: account
  },
  {
    path: "/shopping",
    component: ShoppingContainer,
    children: shopping
  },
  {
    path: "/activity",
    component: ActivityContainer,
    children: activity
  },
  {
    path: "/guide",
    component: GuideContainer,
    children: guide
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next)=> {
  if (to.fullPath == '/shopping/home') {
    document.title = to.meta.title;
  }
  // 是否已登录标识
  const cook = Vue.prototype.$Cookies.get("user");
  // uid
  const uid = localStorage.getItem('uid');
  // 当前机构信息
  const orgInfo = sessionStorage.getItem("orgInfo");
  // 是否有微信公众号
  const noAuthConfig = sessionStorage.getItem("noAuthConfig");
  // 检查是何种浏览器区分
  const u = navigator.userAgent;
  // 注册页面需要-微信内
  const cacheCode = sessionStorage.getItem("code");
  // 微信内-已拒绝授权一次(这个值是后端保存的)
  const backAuth = sessionStorage.getItem("auth");
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // 退出登录标识
  const quite = sessionStorage.getItem("quite");

  // 如果通过别人分享连接进入的需保存分享人的id
  if (to.query.referrerUserId) {
    localStorage.setItem('referrerUserId', to.query.referrerUserId);
  }

  // if ((from.path == '/' || from.path == '/shopping/home') && to.path == '/shopping/search' && to.query.isLoad == 1) {
  //   window.location.replace(`${window.location.origin}/shopping/search`)
  // }




  if ((orgInfo == 'undefined' || !orgInfo) && to.path != '/guide/login') {
    http.get("/org/info?queryH5AppList=true").then(msg => {
      sessionStorage.setItem("orgInfo", JSON.stringify(msg.data));
      
      /**
       * @author: wuyifan
       * @update: 20191219
       * @param {object} webAutoWechatAuth
       * @func
       * @description: 是否开启网页自动微信登录（1开启，0未开启）
       */
      router(msg.data.webAutoWechatAuth);
    });
  } else {
    const wxAutoAuth = JSON.parse(orgInfo).webAutoWechatAuth;
    router(wxAutoAuth);
  }

  function router(wxAutoAuth) {
    if (!cook && !uid) {
      // 用户既没登录，也没有生成uid，需生成uid;
      localStorage.setItem('uid', RandomID())
    }

    if (wxAutoAuth == 0 && !to.query.code && to.path !== "/author") {
      // 如果微信网页授权没有开启，则不自动登录
      if (to.meta.auth && !cook) {
        next(`/guide/login?url=${encodeURIComponent(to.fullPath)}`)
      } else {
        next();
      }
    } else if (
      (to.query.code === "noAuthConfig" || noAuthConfig) &&
      isWeiXin()
    ) {
      if (to.query.code) {
        sessionStorage.setItem("noAuthConfig", to.query.code);
      }
      if (to.query.code) {
        location.assign(funcUrlDel("code"));
      } else {
        next();
      }
    } else if (
      isWeiXin() &&
      !cook &&
      to.query.code &&
      to.query.bind &&
      to.query.bind === "register" &&
      !cacheCode
    ) {
      // 微信内手机号注册——①获取code；②执行注册接口时带入code参数
      sessionStorage.setItem("code", to.query.code);
      if (isiOS && to.path !== location.pathname) {
        location.assign(funcUrlDel("code,state,bind"));
      } else {
        next(funcUrlDel("code,state,bind"));
      }
    } else if (
      (isWeiXin() && quite && !cook && to.meta.auth) ||
      (!isWeiXin() && to.meta.auth && !cook)
    ) {
      console.log('==============')
      /**
       * ①微信内——退出登录后，进入必须登录的页面时，自动跳转到登录页面
       * ②微信外——进入必须登录的页面时，自动跳转到登录页面
       */
      if (isiOS && to.path !== location.pathname) {
        console.log(1)
        location.assign(`/guide/login?url=${encodeURIComponent(to.fullPath)}`);
      } else {
        console.log(2)
        next(`/guide/login?url=${encodeURIComponent(to.fullPath)}`);
      }
    } else if (
      isWeiXin() &&
      to.query.code &&
      to.query.state &&
      cook &&
      to.query.bind &&
      to.query.bind === "wx"
    ) {
      // 绑定微信授权后，重定向地址数据处理。用手机号登录时且该手机号未绑定微信状态，手动触发绑定微信操作
      http
        .get(
          `/weixin/binding?code=${to.query.code}&userId=${
            JSON.parse(cook).userId
          }`
        )
        .then(() => {
          if (isiOS && to.path !== location.pathname) {
            location.assign(funcUrlDel("code,state,bind"));
          } else {
            next(funcUrlDel("code,state,bind"));
          }
        });
    } else if (isWeiXin() && to.query.code && to.query.state && !cook) {
      // 微信自动登录进入授权页面后，重定向地址数据处理——第二步
      http.get(`/weixin/autoLogin?code=${to.query.code}&uid=${uid}&referrerUserId=${localStorage.getItem('referrerUserId')}`).then(res => {
        setCookies({
          token: res.data.__token__
        });
        // 根据token查询用户详细信息
        http.get("/user/info").then(res2 => {
          let v = res2.data;
          v.token = res.data.__token__;
          setCookies(v);
          if (isiOS && to.path !== location.pathname) {
            location.assign(funcUrlDel("code,state"));
          } else {
            next(funcUrlDel("code,state"));
          }
        });
      });
    } else if (
      isWeiXin() &&
      !cook &&
      !quite &&
      !to.query.code &&
      to.path !== "/author" &&
      !cacheCode &&
      !backAuth &&
      to.meta.auth
    ) {
      console.log("12334****************");
      // 首次进入必须登录页面——微信自动登录进入授权页面——第一步
      const u = window.location.origin + to.fullPath;
      window.location.replace(
        `${window.location.origin}/author?redirectUrl=${encodeURIComponent(u)}`
      );
      // next(`/author?redirectUrl=${encodeURIComponent(u)}`);
    } else if (
      isWeiXin() &&
      !cook &&
      !quite &&
      !to.query.code &&
      to.path !== "/author" &&
      !cacheCode &&
      !backAuth &&
      to.path !== "/guide/register"
    ) {
      // 首次进入非必须登录页面——微信自动登录进入授权页面——第一步
      const u = window.location.origin + to.fullPath;
      authFun(u);
    } else {
      // XXX: 修复iOS版微信HTML5 History兼容性问题
      if (isiOS && to.path !== location.pathname) {
        // 此处不可使用location.replace
        location.assign(to.fullPath);
      } else {
        next();
      }
    }
  }






  

})



export default router
