import Vue from "vue";

import Cookies from "js-cookie";

import {
  Toast,
  Header,
  Button,
  Progress,
  Badge,
  Loadmore,
  InfiniteScroll,
  Popup,
  Picker,
  DatetimePicker,
  Indicator,
  MessageBox,
  Range,
  Switch,
  Radio,
} from "mint-ui";

import { Checkbox, CheckboxGroup } from 'vant';
import 'vant/lib/index.css';
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.component(Header.name, Header);
Vue.use(InfiniteScroll);

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import clipper from "@/utils/clipper";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
import VueLazyload from 'vue-lazyload';
// 图片懒加载
Vue.use(VueLazyload, {
  loading: require('@/assets/images/img/image.png'),//加载中图片，一定要有，不然会一直重复加载占位图
  error: require('@/assets/images/img/image.png')  //加载失败图片
});

Vue.component("swiper", swiper);
Vue.component("swiper-slide", swiperSlide);
// 瀑布流
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)


import "swiper/dist/css/swiper.css";
import "mint-ui/lib/style.css";
import "@/assets/styles/small.css";
import "@/assets/styles/menu.css";
import "@/assets/styles/tabbar.css";
import "@/assets/styles/common.less";
import "@/assets/styles/lib.base.css";

// 自定义组件
const commonHeader = resolve => {
  import("@/components/global/Header").then(module => {
    resolve(module);
  });
};
const commonTabBar = resolve => {
  import("@/components/global/TabBar").then(module => {
    resolve(module);
  });
};
// 加载动画
const Loading = resolve => {
  import("@/components/global/Loading").then(module => {
    resolve(module);
  });
};
const LoadCompletion = resolve => {
  import("@/components/global/LoadCompletion").then(module => {
    resolve(module);
  });
};
const NoData = resolve => {
  import("@/components/global/NoData").then(module => {
    resolve(module);
  });
};

// 微信分享
const WeChatShare = resolve => {
  import("@/components/global/WeChatShare").then(module => {
    resolve(module);
  });
};

var options = {
  preload: [1, 1],
  // Buttons/elements
  closeEl: false,
  captionEl: true,
  fullscreenEl: false, //关闭全屏按钮
  zoomEl: false,
  shareEl: false,
  counterEl: true,
  arrowEl: false,
  preloaderEl: true,
  // Delay until loading indicator is displayed
  loadingIndicatorDelay: 500,
  // Function builds caption markup
  addCaptionHTMLFn: function(item, captionEl, isFake) {
    // item      - slide object
    // captionEl - caption DOM element
    // isFake    - true when content is added to fake caption container
    //             (used to get size of next or previous caption)
    if (!item.title) {
      captionEl.children[0].innerHTML = "";
      return false;
    }
    captionEl.children[0].innerHTML = item.title;
    return true;
  }
};
// 图片剪贴组件
Vue.use(clipper);
Vue.use(preview, options);
Vue.component("no-data", NoData);
Vue.component("wechat-share", WeChatShare);
Vue.component("load-completion", LoadCompletion);
Vue.component("common-header", commonHeader);
Vue.component("tabBar", commonTabBar);
Vue.component("loading", Loading);
Vue.component(Progress.name, Progress);
Vue.component(Badge.name, Badge);
Vue.component(Button.name, Button);
Vue.component(Popup.name, Popup);
Vue.component(Picker.name, Picker);
Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Range.name, Range);
Vue.component(Loadmore.name, Loadmore);
Vue.component(Switch.name, Switch);

Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$Indicator = Indicator;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$toast = function(v, iconClass, d) {
  Toast({
    message: v,
    position: "middle",
    className: iconClass || "toast-error",
    duration: d || 2000
  });
};

export default Vue;
