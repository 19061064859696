<template>
  <div class="home">
    <!-- 背景 #f7f7f7 start -->
    <div class="bd-color"></div>
    <!-- 背景 #f7f7f7 end -->
    <common-header :cb="getDataAll"></common-header>
    <div class="banner-box" v-if="banners.length > 0" :style="`background: linear-gradient(180deg, ${banners[HomeBannerIndex].colorGradientStart} 0%, #f7f7f7 85%)`">
      <!-- 功能区 start -->
      <div class="banner-fun">
        <!-- <div class="sao">
          <i class="icon-saoyisao"></i>
        </div> -->
        <!-- 搜索 start -->
        <div class="search-box" @click="$router.push({path:'/shopping/search?isLoad=1'})">
          <input class="search-input" type="text" placeholder="搜索商品">
          <i class="icon-search-fdj"></i>
        </div>
        <!-- 搜索 end -->
      </div>
      <!-- 功能区 end -->
      <swiper :options="bannerSwiper" class="home-swiper" ref="bannerSwiper">
        <swiper-slide v-for="(item, index) in banners" :key="`banner${index}`">
          <router-link :to="item.url" v-if="item.url != ''">
            <div class="img" :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
          </router-link>
          <div class="img" v-else :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
        </swiper-slide>
        <div class="swiper-pagination gnpagination" slot="pagination"></div>
      </swiper>
    </div>

    <!-- 菜单 start -->
    <div class="menu-box">
      <!-- type 区域类型;1=首页、2=主导航，3=个人中心 -->
      <div class="content">
        <div class="item"
        v-for="(item, i) in orgInfo.h5AppList"
        :key="'h5AppList1' + i"
        v-if="item.type == 1">
          <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
            <img :key="requestImgUrl(item.iconSelect)" alt="" v-lazy="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`">
            <p>{{item.name}}</p>
          </a>
          <router-link :to="item.targetUrl+'&refresh=true'" v-else>
            <img :key="requestImgUrl(item.iconSelect)" alt="" v-lazy="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`">
            <p>{{item.name}}</p>
          </router-link>
        </div>
      </div>
    </div>
    <!-- 菜单 end -->

    <!-- 广告区域 start -->
    <div class="gd2-postions" v-if="gdBanner.length > 0">
      <swiper :options="bannerSwiper2" class="home-swiper" ref="bannerSwiper2">
        <swiper-slide v-for="(item, index) in gdBanner" :key="`banner${index}`">
          <router-link :to="item.url" v-if="item.url != ''">
            <div class="img" :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
          </router-link>
          <div class="img" v-else :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
        </swiper-slide>
      </swiper>
    </div>
    <!-- 广告区域 end -->

    <!-- 限时秒杀 start -->
    <div class="warp" v-if="Seckills.length > 0">
      <div class="second-kill">
        <div class="title">
          <div class="l">
            <div class="name">限时秒杀</div>
            <div class="count-down">
              <div class="st">{{downObj.hour}}</div>:<div class="st">{{downObj.minute}}</div>:<div class="st">{{downObj.second}}</div>
            </div>
          </div>
          <div class="more" @click="$router.push({path:'/activity/seckill' })">
            更多好货
            <i class="icon-more-right"></i>
          </div>
        </div>

        <div class="commodity-list" >
          <swiper :options="commoditySwiper">
            <swiper-slide v-for="(item, index) in Seckills" :key="index">
              <div class="item" @click="$router.push({path: '/shopping/details', query: {id: item.commodityId, specid: item.commoditySpecId}})">
                <div class="img" :style="`background-image:url('${getGoodsImg(item)}?x-oss-process=image/resize,m_lfit,w_200')`">
                    <span class="quehuo" v-if="item.orderQuantity>=item.seckillQuantity">已抢光</span>
                </div>
                <!-- 进度条  start-->
                <div class="progress-box">
                  <mt-progress :value="getGoddsScale(item)"></mt-progress>
                  <div class="text">已抢{{getGoddsScale(item)}}%</div>
                </div>
                <!-- 进度条  end-->
                <div class="seckill-price-box">
                  <div class="present-price">
                    <div class="unit">￥</div>
                    <div class="moeny">{{item.seckillPrice}}</div>
                    <div class="unit" v-if="item.unitName">/{{item.unitName}}</div>
                  </div>
                  <div class="org-price">￥{{item.price}}</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 限时秒杀 end -->

     <!-- 菜单type=4 start -->
    <div class="applist-4 clearfix" v-if="applist4.length>0">
      <div class="content" v-if="applist4.length==1">
        <div class="item-box-1" v-for="(item, i) in applist4" :key="'h5AppList4' + i">
          <h2 class="name" v-if="item.showName==1">{{item.name}}</h2>
          <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_400`" alt="">
          </a>
          <router-link :to="item.targetUrl" v-else>
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_400`" alt="">
          </router-link>
        </div>
      </div>

      <div class="content" v-if="applist4.length==2">
        <div class="item-box-2" v-for="(item, i) in applist4" :key="'h5AppList4' + i">
          <h2 class="name" v-if="item.showName==1">{{item.name}}</h2>
          <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_400`" alt="">
          </a>
          <router-link :to="item.targetUrl" v-else>
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_400`" alt="">
          </router-link>
        </div>
      </div>

      <div class="content" v-if="applist4.length==3">
        <div class="item-box-3">
          <h2 class="name" v-if="applist4[0].showName==1">{{applist4[0].name}}</h2>
          <a :href="applist4[0].targetUrl" v-if="isHttp(applist4[0].targetUrl)">
            <img :src="`${requestImgUrl(applist4[0].iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`" alt="">
          </a>
          <router-link :to="applist4[0].targetUrl" v-else>
            <img :src="`${requestImgUrl(applist4[0].iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`" alt="">
          </router-link>
        </div>
        <div class="item-box-3">
          <div class="item-box-3-1">
            <h2 class="name" v-if="applist4[1].showName==1">{{applist4[1].name}}</h2>
            <a :href="applist4[1].targetUrl" v-if="isHttp(applist4[1].targetUrl)">
              <img :src="requestImgUrl(applist4[1].iconSelect)" alt="">
            </a>
            <router-link :to="applist4[1].targetUrl" v-else>
              <img :src="requestImgUrl(applist4[1].iconSelect)" alt="">
            </router-link>
          </div>
          <div class="item-box-3-2">
            <h2 class="name" v-if="applist4[2].showName==1">{{applist4[2].name}}</h2>
            <a :href="applist4[2].targetUrl" v-if="isHttp(applist4[2].targetUrl)">
              <img :src="requestImgUrl(applist4[2].iconSelect)" alt="">
            </a>
            <router-link :to="applist4[2].targetUrl" v-else>
              <img :src="requestImgUrl(applist4[2].iconSelect)" alt="">
            </router-link>
          </div>
        </div>
      </div>

    </div>
    <!-- 菜单type=4 end -->

    <!-- 菜单type=6 start -->
    <div class="applist-6 clearfix" v-if="applist6.length>0">
      <div class="content">
        <div :class="'item-box-'+applist6.length+' item-box'" v-for="(item, i) in applist6" :key="'h5AppList6' + i">
          <h2 class="name" v-if="item.showName==1">{{item.name}}</h2>
          <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`" alt="">
          </a>
          <router-link :to="item.targetUrl" v-else>
            <img :src="`${requestImgUrl(item.iconSelect)}?x-oss-process=image/resize,m_lfit,w_300`" alt="">
          </router-link>
        </div>
      </div>

    </div>
    <!-- 菜单type=6 end -->


    <!-- 特价专区 菜场特卖 start -->
    
    <div class="special-offer warp clearfix" v-if="tagsPlateShow && tagsDa.length > 0">
      <div class="item-box" :style="`width:${firstWidth}%`">
        <div class="item" v-if="zone3.length > 0 && tagsDa.length > 0" >
          <h2 class="name">{{tagsDa[0].tagName}}</h2>
          <swiper class="sp-list" :options="CDZGSwiper">
            <swiper-slide v-for="(item, index) in zone3" :key="index">
              <router-link :to="`/shopping/search?tagsCode=${tagsDa[0].tagCode}&title=${tagsDa[0].tagName}`" class="sp-item">
                <img :src="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="">
                <div class="price-box">
                  <div class="present-price">
                    <div class="unit">￥</div>
                    <div class="moeny">{{(Number(item.specList[0].price) + item.specList[0].deposit)}}<span class="unit">{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? '/'+item.unitName : '')}}</span></div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="item-box" :style="`width:${twoWidth}%`" v-if="zone1.length > 0 && tagsDa.length > 1">
        <div class="item">
          <h2 class="name">{{tagsDa[1].tagName}}</h2>
          <swiper class="sp-list " :class="twoWidth == 25 ? 'tjzq-swiper' : ''" :options="TJZQSwiper">
            <swiper-slide v-for="(item, index) in zone1" :key="index">
              <router-link :to="`/shopping/search?tagsCode=${tagsDa[1].tagCode}&title=${tagsDa[1].tagName}`"  class="sp-item">
                <img :src="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="">
                <div class="price-box">
                  <div class="present-price">
                    <div class="unit">￥</div>
                    <div class="moeny">{{(Number(item.specList[0].price) + item.specList[0].deposit)}}<span class="unit">{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? '/'+item.unitName : '')}}</span></div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="item-box" :style="`width:${threeWidth}%`" v-if="zone2.length > 0 && tagsDa.length > 2">
        <div class="item">
          <h2 class="name">{{tagsDa[2].tagName}}</h2>
          <swiper class="sp-list tjzq-swiper" :options="CCTMSwiper">
            <swiper-slide v-for="(item, index) in zone2" :key="index">
              <router-link :to="`/shopping/search?tagsCode=${tagsDa[2].tagCode}&title=${tagsDa[2].tagName}`" class="sp-item">
                <img :src="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="">
                <div class="price-box">
                  <div class="present-price">
                    <div class="unit">￥</div>
                    <div class="moeny">{{(Number(item.specList[0].price) + item.specList[0].deposit)}}<span class="unit">{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? '/'+item.unitName : '')}}</span></div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 特价专区 菜场特卖 end -->



    <!-- 广告位 start -->
    <div class="advertisement warp" v-if="centerGd.length > 0">
      <swiper :options="advertiseSwiper" ref="advertise">
        <swiper-slide v-for="(item, index) in centerGd" :key="index">
          <div class="ad-banner" :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
        </swiper-slide>
      </swiper>
      <!-- <swiper :options="advertiseSwiper">
        <swiper-slide v-for="(item, index) in centerGd" :key="index">
          <a :href="item.url" v-if="isHttp(item.url)" data-a="a">
            <div class="ad-banner" :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
          </a>
          <router-link :to="item.url" data-a="router-link" v-else>
            <div class="ad-banner" :style="`background-image:url('${requestImgUrl(item.img)}')`"></div>
          </router-link>
        </swiper-slide>
      </swiper> -->
    </div>
    <!-- 广告位 end -->

    <!-- 热销好货 start -->
    <div class="goods-list">
        <div class="tab-box" ref="tabbox">
          <div class="goods-tab-box" :class="fixed ? 'fixed' : ''" :style="`top:${isWeiXin() ? '0' : '40'}px`">
            <h2 class="title" :class="goodsIndex == 0 ? 'active' : ''" @click="changeGodsTab(0)">为您推荐</h2>
            <h2 class="title" :class="goodsIndex == 1 ? 'active' : ''" @click="changeGodsTab(1)">热销爆品</h2>
            <h2 class="title" :class="goodsIndex == 2 ? 'active' : ''" @click="changeGodsTab(2)">新品上架</h2>
          </div>
        </div>

        <waterfall :col='2' :data="commodityListAll[goodsIndex]" class="goods-ul" v-for="(item, index) in [0,1,2]" :key="index" v-if="index==goodsIndex">
          <template>
            <li class="goods-li" v-for="(item, index) in commodityListAll[goodsIndex]" :key="index">
              <div class="item">
                <div class="top" @click="$router.push({path:'/shopping/details', query: {id: item.id}})">
                  <div class="good-imgbox pre">
                    <img class="good-img" :key="`${requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)}`" v-lazy="`${requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)}?x-oss-process=image/resize,m_lfit,w_400`" alt="">
                    <span class="quehuo" v-if="item.specList && item.specList.length > 0 && item.specList[0].inventory === 0">缺货</span>
                  </div>
                  <div class="good-name">{{item.name}}</div>
                  <!-- 月销 start -->
                  <div class="month-sales" v-if="(item.monthlySales + item.virtualMonthlySales) > 1000">月销{{item.monthlySales + item.virtualMonthlySales}}{{item.unitName != '' ? item.unitName : ''}}</div>
                  <!-- 月销 end -->
                  <div class="no-unitName" v-if="item.specList[0].unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 && item.specList[0].price > 0">￥{{(Number(item.specList[0].price) + item.specList[0].deposit)}}{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? `/${item.unitName}` : '')}}</div>
                </div>
                <div class="bottom">
                  <div class="price-box" v-if="item.specList[0].unitPrice > 0">
                    <div class="present-price">
                      <div class="unit">￥</div>
                      <div class="moeny">{{item.specList[0].unitPrice}}<span class="unit">{{item.specList[0].minUnitName != '' ? '/'+item.specList[0].minUnitName : ''}}</span></div>
                    </div>
                    <!-- <div class="org-price">￥88</div> -->
                  </div>
                  <div class="is-certified" v-else>{{isCertified()}}</div>
                  <!--<div class="icon-car-btn" @click="SelectProducts(item.id, item.specList[0].id, item.inventory)"></div>-->

                  <div class="specs-btn-box jb-r-l" v-if="item.specList && item.specList.length > 1" @click.stop="$router.push({path:'/shopping/details', query: {id: item.id}})" >{{ item.specsShow ? "收起" : "规格" }}</div>
                  <div class="icon-car-btn" v-else @click.stop=" SelectProducts(item.id,item.specList[0].id,item.inventory)"></div>

                </div>
              </div>
            </li>
          </template>
        </waterfall>
        <no-data v-if="nodata"></no-data>
      </div>
    <!-- 热销好货 end -->
    <!-- 未登录的时候显示 start -->
    <router-link to="/account/home" class="no-login" v-if="commodityListAll[goodsIndex].length>0 && commodityListAll[goodsIndex][0].unitPrice<0" >登录认证后显示价格</router-link>
    <!-- 未登录的时候显示 end -->

    <!-- 广告弹窗 start -->
    <mt-popup
      position="center"
      class="w100 gd-alert-pop"
      v-model="GdPopup"
    >
    <div class="gd-alert-box" v-if="alertGd.length > 0">
      <swiper :options="alertGdSwiper">
        <swiper-slide v-for="(item, index) in alertGd" :key="index">
          <a :href="item.url" v-if="isHttp(item.url)">
            <img :src="`${requestImgUrl(item.img)}?x-oss-process=image/resize,m_lfit,w_800`" alt="">
          </a>
          <router-link :to="item.url" v-else>
            <img :src="`${requestImgUrl(item.img)}?x-oss-process=image/resize,m_lfit,w_800`" alt="">
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination alert-pagination" slot="pagination"></div>
      </swiper>
      <div class="icon-alert-clost" @click="GdPopup = false"></div>
    </div>
    </mt-popup>
    <!-- 广告弹窗 end -->

    <div style="padding-bottom: 40px; font-size: 12px; text-align: center; color: #a7a7a7; text-decoration: none;"> <a style="color: #a7a7a7;" href="https://beian.miit.gov.cn/">蜀ICP备2021030387号-1</a></div>

    <tabBar></tabBar>
  </div>
</template>

<style lang="less" scoped>
.gd2-postions{
  margin-top: .2rem;
  padding: 0 .2rem;
  .home-swiper{
    height: 1.3rem;
    border-radius: 0.25rem;
    .img{
      background-position: center center;
      height: 1.3rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
.gd-alert-box{
  text-align: center;
  .icon-alert-clost{
    margin: .4rem auto 0;
  }
  img{
    max-width: 85%;
    border-radius: .25rem;
  }
}
.gd-alert-pop{
  background: none;
}
.no-login{
    position: fixed;
    bottom: 1.13rem;
    left: 0;
    width: 100%;
    background: #ccc;
    text-align: center;
    color: #ff9662;
    font-size: .24rem;
    padding: .1rem;
    background: #ffe3cb;
    z-index: 999;
  }
.month-sales{
  margin-top: -0.12rem;
}

.month-sales, .no-unitName{
  padding: 0 0.25rem;
  font-size: .25rem;
  color: #ccc;
}
.no-unitName{
  margin: 0.2rem 0 -0.15rem 0;
}
.banner-fun {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  margin-bottom: 0.2rem;
  .sao {
    width: 0.62rem;
    height: 0.62rem;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.26rem;
  }
}
.search-box {
  width: 100%;
  height: 0.62rem;
  background: #fff;
  border-radius: 0.3rem;
  position: relative;
  .icon-search-fdj {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-left: -0.75rem;
  }
  .search-input {
    width: 100%;
    height: 100%;
    font-size: 0.26rem;
    text-align: center;
  }
}
.goods-list {
  min-height: 11.4rem;
  margin-bottom: 0.2rem;
  border-radius: 0.25rem;
  padding: 0 0.1rem;
  margin-top: 0.2rem;

  .tab-box {
    height: 1.12rem;
    margin-bottom: 0.2rem;
    padding: 0 0.1rem;
  }
  .goods-tab-box {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.2rem 0.2rem;
    background: #fff;
    color: #333;
    border-radius: 0.25rem;
    &.fixed {
      position: fixed;
      top: 40px;
      left: 0;
      width: 100%;
      z-index: 999;
      background: #fff;
      border-bottom: 1px solid #eee;
    }
    .title {
      padding-bottom: 0.2rem;
    }
    .title.active {
      position: relative;
      color: #ff7241;
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0.6rem;
        height: 0.05rem;
        background: #ff7241;
        transform: translateX(-50%);
        content: "";
      }
    }
  }
}

.goods-ul {
  .goods-li {
    padding: 0 0.1rem;
    margin-bottom: 0.2rem;

    .item {
      background: #fff;
      border-radius: 0.25rem;
      overflow: hidden;
      box-shadow: 0 0 0.1rem #efefef;
      padding-bottom: 0.25rem;
      .good-img {
        width: 100%;
        height: 3.45rem;
      }
    }

    .quehuo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 5;
      background-color: rgba(167, 167, 167, 0.7);
      color: #fff;
      width: 1.4rem;
      height: 1.4rem;
      font-size: 0.32rem;
      text-align: center;
      line-height: 1.4rem;
      display: block;
      border-radius: 1rem;
    }

    .good-name {
      font-size: 0.26rem;
      color: #212121;
      padding: 0 0.25rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .bottom {
      padding: 0 0.25rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
.advertisement {
  .ad-banner {
    height: 2.8rem;
    border-radius: 0.25rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-container {
    height: 2.8rem;
  }
  margin: 0.2rem 0;
  img {
    border-radius: 0.25rem;
  }
}
.special-offer {
  .item-box {
    float: left;
    padding-right: 0.06rem;
    &:last-child {
      padding-right: 0;
    }
  }
  .name {
    font-size: 0.34rem;
    color: #212121;
    font-weight: bold;
    padding-top: 0.34rem;
    padding-left: 0.24rem;
    padding-bottom: 0.28rem;
    line-height: 1;
  }
  .sp-list {
    display: flex;
    .sp-item {
      flex: 1;
      text-align: center;
      display: block;
      margin: auto;
    }
    .sp-item img {
      width: 1.5rem;
      height: 1.4rem;
      margin: auto;
    }
  }
  .item {
    margin-top: 0.2rem;
    padding-bottom: 0.36rem;
    background: #fff;
    border-radius: 0.25rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.price-box {
  display: flex;
  align-items: center;
  margin-top: 0.18rem;
  justify-content: center;
}
.seckill-price-box {
  display: flex;
  align-items: center;
  margin-top: 0.18rem;
  justify-content: center;
  .present-price {
    display: flex;
    align-items: flex-end;
    color: #ff7241;
    .unit {
      font-size: 0.20rem;
    }
    .moeny {
      font-size: 0.30rem;
    }
  }
  .org-price {
    font-size: 0.20rem;
    color: #bfbfbf;
    text-decoration: line-through;
    line-height: 1;
    margin-left: 0.05rem;
  }
}
.org-price {
  font-size: 0.24rem;
  color: #bfbfbf;
  text-decoration: line-through;
  line-height: 1;
  margin-left: 0.1rem;
}
.present-price {
  display: flex;
  align-items: flex-end;
  color: #ff7241;
  .unit {
    font-size: 0.24rem;
  }
  .moeny {
    font-size: 0.32rem;
  }
}
.commodity-list {
  padding-top: 0.28rem;
  padding-bottom: 0.4rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  .item {
    width: 2rem;
    .progress-box {
      position: relative;
      .text {
        position: absolute;
        color: #fff;
        font-size: 0.18rem;
        text-align: center;
        width: 100%;
        top: 0;
        left: 0;
        line-height: 0.25rem;
      }
    }
    .img {
      height: 1.5rem;
      border-radius: 0.05rem;
      margin-bottom: 0.2rem;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.second-kill {
  background: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-top: 0.2rem;
  .title {
    .l {
      display: flex;
      align-items: center;
    }
    .more {
      width: 1.67rem;
      height: 0.53rem;
      background: #fff;
      border-radius: 0.26rem;
      font-size: 0.24rem;
      color: #ff7341;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-more-right {
        margin-left: 0.07rem;
        border-radius: 50%;
      }
    }
    .count-down {
      display: flex;
      color: #fff;
      margin-left: 0.15rem;
      .st {
        min-width: 0.4rem;
        padding: 0 0.05rem;
        height: 0.4rem;
        background: #fff;
        font-size: 0.24rem;
        color: #ff7341;
        text-align: center;
        border-radius: 0.05rem;
        line-height: 0.4rem;
        margin: 0 0.02rem;
      }
    }
    .name {
      font-size: 0.36rem;
      font-weight: bold;
      color: #fff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    height: 0.9rem;
    background: rgba(255, 113, 65, 1); /* Old Browsers */
    background: -moz-linear-gradient(
      left,
      rgba(255, 113, 65, 1) 0%,
      rgba(255, 185, 139, 1) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(255, 113, 65, 1)),
      color-stop(100%, rgba(255, 185, 139, 1))
    ); /* Chrome, Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 113, 65, 1) 0%,
      rgba(255, 185, 139, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 113, 65, 1) 0%,
      rgba(255, 185, 139, 1) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 113, 65, 1) 0%,
      rgba(255, 185, 139, 1) 100%
    ); /* IE 10+ */
    background: linear-gradient(
      to right,
      rgba(255, 113, 65, 1) 0%,
      rgba(255, 185, 139, 1) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 ); /* IE6-9 */
  }
  .quehuo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 5;
    background-color: rgba(167, 167, 167, 0.7);
    color: #fff;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 0.3rem;
    text-align: center;
    line-height: 1.2rem;
    display: block;
    border-radius: 1rem;
  }
}
.banner-box {
  position: relative;
  padding: 0.2rem;
  .home-swiper {
    height: 2.8rem;
  }

  .home-swiper .img {
    height: 2.8rem;
    border-radius: 0.25rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.menu-box {
  text-align: center;
  padding: 0 0.2rem;
  .content {
    background: #fff;
    border-radius: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem 0;
    .item {
      padding: 0.2rem 0;
      width: 20%;
      min-width: 20%;
      max-width: 20%;
      img {
        width: 0.98rem;
        height: 0.98rem;
        display: block;
        margin: 0 auto 0.2rem;
      }
    }
  }
  p {
    font-size: 0.26rem;
    color: #212121;
  }
}
.applist-4 {
  padding: 0 0.14rem;
  img{
    border-radius: 0.25rem;
    height: 2rem;
  }
  .name{
    font-size: 0.34rem;
    color: #212121;
    font-weight: bold;
    padding-top: 0.34rem;
    padding-left: 0.24rem;
    padding-bottom: 0.28rem;
    line-height: 1;
    position: absolute;
  }
  margin: 0.2rem 0 0 0;
  .item-box-1 {
    width:100%;
    img{
      width: 100%;
      height: 2rem;
    }
  }
  .item-box-2 {
    float: left;
    padding: 0 0.06rem;
    width:50%;
    img{
      width: 100%;
      height: 2rem;
    }
  }
  .item-box-3{
    float: left;
    padding: 0 0.06rem;
    width:50%;
    img{
      width: 100%;
      height: 4.06rem;
    }
    .item-box-3-1{
      margin-bottom: 0.06rem;
      img{
        width: 100%;
        height: 2rem;
      }
    }
    .item-box-3-2{
      img{
        width: 100%;
        height: 2rem;
      }
    }
  }
}
.applist-6 {
  padding: 0 0.14rem;
  img{
    border-radius: 0.25rem;
    height: 2.5rem;
  }
  margin: 0.2rem 0 0 0;
  .item-box{
    float: left;
    padding: 0 0.06rem;
    img{
      width: 100%;
    }
    .name{
      font-size: 0.34rem;
      color: #212121;
      font-weight: bold;
      padding-top: 0.34rem;
      padding-left: 0.24rem;
      padding-bottom: 0.28rem;
      line-height: 1;
      position: absolute;
    }
  }
  .item-box-1{
    width: 100%;
  }
  .item-box-2{
    width: calc(100%/2);
  }
  .item-box-3{
    width: calc(100%/3);
  }
  .item-box-4{
    width: calc(100%/4);
  }
}
.specs-btn-box{
  color: #fff;
  font-size: .24rem;
  padding: .1rem;
  border-radius: .1rem;
  display: flex;
  margin-top: 0.18rem;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  requestImgUrl,
  getGoodsImg,
  CountDown,
  isWeiXin,
  DateFormat
} from "@/utils/common";
import { isHttp, isCertified } from "@/utils/utils";
export default {
  name: "Home",
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.DateFormat = e => DateFormat(e);
    this.isHttp = v => isHttp(v);
    this.getGoodsImg = v => getGoodsImg(v);
    this.isWeiXin = v => isWeiXin(v);
    this.CountDown = v => CountDown(v);
    this.isCertified = () => isCertified();
    return {
      // 广告弹窗显示
      GdPopup: false,
      uid: localStorage.getItem("uid"),
      // 商品列表tab
      goodsIndex: 0,
      nodata: false,
      // banner图
      banners: [],
      gdBanner: [],
      alertGdSwiper:{
        
        pagination: {
          el: ".alert-pagination",
          // type: "fraction"
        },
      },
      advertiseSwiper: {
        autoplay: true,
        disableOnInteraction: false,
        on: {
          tap: ()=> {
            let swiper = this.$refs.advertise.swiper;
            let item = this.centerGd[swiper.activeIndex];
            if (this.isHttp(item.url)) {
              window.location.href = item.url;
            } else {
              this.$router.push(item.url);
            }
          },
        },
      },
      firstWidth: "50",
      twoWidth: "25",
      threeWidth: "25",
      // 产地直供swiper
      CDZGSwiper: {
        autoplay: true,
        slidesPerView: 2,
        loop: true,
        disableOnInteraction: false
      },
      // 特价专区swiper
      TJZQSwiper: {
        autoplay: true,
        loop: true,
        slidesPerView: 2,
        effect: "fade",
        disableOnInteraction: false
      },
      CCTMSwiper: {
        autoplay: true,
        loop: true,
        slidesPerView: 2,
        effect: "fade",
        disableOnInteraction: false
      },
      // banner索引值
      HomeBannerIndex: 0,
      bannerSwiper2: {
        autoplay: {
          disableOnInteraction: false,
        },
      },
      bannerSwiper: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".gnpagination",
          type: "fraction"
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = this.$refs.bannerSwiper.swiper;
            if (swiper) {
              let index = swiper.realIndex;
              this.HomeBannerIndex = index;
            }
          }
        }
      },
      commoditySwiper: {
        slidesPerView: "auto",
        spaceBetween: 5
      },
      // 限时秒杀
      Seckills: [],
      // 倒计时
      downObj: {},
      // 商品列表
      commodityListAll:{
        0:[],
        1:[],
        2:[]
      },
      // 特价专区
      zone1: [],
      zone2: [],
      zone3: [],
      timer: null,
      fixed: false,
      centerGd: [],
      tagsPlateShow: true,

      tagsDa: [],
      applist4: [],
      applist6: [],
      alertGd: [],
    };
  },
  activated() {
    if(this.orgInfo && this.orgInfo.webName){
      document.title = this.orgInfo.webName;
    }
    document.documentElement.scrollTop = sessionStorage.getItem('scrollTop')
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    
    const orgInfo = sessionStorage.getItem("orgInfo");
    if(orgInfo){
      this.applist4 = JSON.parse(orgInfo).h5AppList.filter(item=>item.type===4).filter((item,index)=>index<3);
      this.applist6 = JSON.parse(orgInfo).h5AppList.filter(item=>item.type===6).filter((item,index)=>index<4);
    }
  },
  methods: {
    // 页面滚动
    handleScroll(e) {
      let offsetTop = this.$refs.tabbox.offsetTop;

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      sessionStorage.setItem('scrollTop', scrollTop);
      if (scrollTop + 40 >= offsetTop) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
    // 商品切换
    changeGodsTab(i) {
      this.goodsIndex = i;
      if(!this.commodityListAll[i] || this.commodityListAll[i].length==0){
        this.getCommodityList(i);
      }
    },
    getCommodityList(i){
      let obj  = {};
      if(i==0){
        obj = {
          recommend: 1
        }
      }else if(i==1){
        obj = {
          sortField: "orderQuantity DESC"
        }
      }else {
        obj = {
          sortField: "createTime DESC"
        }
      }
      this.GetCommodityList(obj).then(res => {
        this.nodata = res.data.total > 0 ? false : true;
        this.commodityListAll[i] = res.data.list;
      });
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if (inventory <= 0) {
        this.$toast("卖光啦！");
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid
      }).then(res => {
        if (res.status == 200) {
          this.$toast("加入购物车成功");
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then(res => {
            this.goodsNumMuta(res.data.quantity);
          });
        }
      });
    },
    /**
     * @author: wangjing
     * @update: 20191030
     * @func
     * @description: 导航的回调函数，初始化页面数据
     */
    getDataAll() {
      // 广告
      this.getAd({
        positionCode: "M001"
      }).then(res => {
        this.banners = res.data;
      });

      this.getAd({
        positionCode: "BANNER2"
      }).then(res => {
        this.gdBanner = res.data;
      });

      // 广告弹窗 2021-03-12
      // localStorage.removeItem('alertGdInfo')
      this.getAd({
        positionCode: "SYTCGG"
      }).then(res => {
        let alertGdInfo = localStorage.getItem('alertGdInfo') ? JSON.parse(localStorage.getItem('alertGdInfo')) : '';
        this.alertGd = res.data;
        if (res.data.length > 0) {
          let gd_expire_time = localStorage.getItem('gd_expire_time');
          if (alertGdInfo == '' || alertGdInfo.id != res.data[0].id || !this.isExpireTime()) {
            localStorage.setItem('alertGdInfo', JSON.stringify({
              id: res.data[0].id,
              num: 1,
            }))
            localStorage.setItem('gd_expire_time', new Date().getTime() + 24 * 60 * 60 * 1000)
            this.GdPopup = true;
          } else if (alertGdInfo.num < 2) {
            localStorage.setItem('alertGdInfo', JSON.stringify({
              id: alertGdInfo.id,
              num: alertGdInfo.num + 1,
            }))
            this.GdPopup = true;
          }
        }
      });

      this.getAd({
        positionCode: "HomeCenterBanner"
      }).then(res => {
        this.centerGd = res.data;
      });

      // 获取限时秒杀
      this.GetSeckillList().then(res => {
        let datas = res.data.filter(item => item.status == 0);
        if (datas.length > 0) {
          this.Seckills = datas[0].specList;
          this.MsDownTime(datas[0].endTime);
        }
      });

      this.getTagsSp({
        homeShow: 1,
        typeCode: "MALL_COMMODITY",
        pageSize: 3
      }).then(res => {
        // 特价专区
        // this.GetCommodityList({
        //   tagsCode: "TJZQ"
        // }).then(res => {
        //   this.zone1 = res.data.list;
        // });
        // // 菜场特卖
        // this.GetCommodityList({
        //   tagsCode: "CCTM"
        // }).then(res => {
        //   this.zone2 = res.data.list;
        // });
        // // 产地直供
        // this.GetCommodityList({
        //   tagsCode: "CDZG"
        // }).then(res => {
        //   this.zone3 = res.data.list;
        // });

        // 商品列表查询
        this.getCommodityList(0);
        this.getCommodityList(1);
        this.getCommodityList(2);

        /**
       * 根据返回记录条数显示模块，假设模块总宽度4：
        返回3条：2:1:1（宽度：2，1，1）
        返回2条：1:1（宽度：2，2）
        返回1条：1（宽度：4）
        返回0条：不显示模块
       */
        let len = res.data.list.length;
        this.tagsDa = res.data.list;
        if (len == 0) {
          this.tagsPlateShow = false;
        } else if (len == 1) {
          this.firstWidth = 100;
          this.twoWidth = 0;
          this.threeWidth = 0;
          this.CDZGSwiper.slidesPerView = 4;
        } else if (len == 2) {
          this.firstWidth = 50;
          this.twoWidth = 50;
          this.threeWidth = 0;
          this.TJZQSwiper = {
            autoplay: true,
            slidesPerView: 2,
            loop: true,
            disableOnInteraction: false
          };
        } else {
          this.firstWidth = 50;
          this.twoWidth = 25;
          this.threeWidth = 25;
        }
      });
    },
    // 判断是否过期
    isExpireTime() {
      // true 未过期 false过期
      let gd_expire_time = localStorage.getItem('gd_expire_time');
      if (!gd_expire_time) {
        console.log('广告过期了')
        return false;
      } else if (gd_expire_time > new Date().getTime()) {
        console.log('广告没过期')
         return true;
      } else {
        console.log('广告过期了')
        return false;
      }
    },
    // 限时秒杀商品倒计时
    MsDownTime(endTime) {
      // 计算倒计时
      let nowDate = this.DateFormat(new Date());
      let endTimeRub = new Date(endTime.replace(/-/g, "/")).getTime();
      // 当前时间时间搓
      let currentTime = new Date(nowDate.replace(/-/g, "/")).getTime();
      let differTime = (endTimeRub - currentTime) / 1000;
      this.timer = setInterval(() => {
        differTime = differTime - 1;
        if (differTime <= 0) {
          clearInterval(this.timer);
        }
        this.downObj = this.CountDown(differTime);
      }, 1000);
      this.downObj = this.CountDown(differTime);
    },
    /**
     * @author: 吴一帆
     * @update: 20201215
     * @func
     * @description: 计算限时秒杀商品比列
     */
    getGoddsScale(e) {
      let scale = e.orderQuantity / e.seckillQuantity;
      if (scale >= 1) {
        scale = 100;
      } else {
        scale = Number((scale * 100).toFixed(1));
      }
      return scale;
    },
    ...mapMutations(["goodsNumMuta"]),
    ...mapActions(["getAd"]),
    ...mapActions("shopping", [
      "getCommodityCount",
      "AddCommodityCart",
      "GetSeckillList",
      "GetSeckillSpecList",
      "GetCommodityList",
      "getTagsSp"
    ])
  },
  deactivated() {
    clearInterval(this.timer);
    window.removeEventListener("scroll", this.handleScroll);
    this.fixed = false;
  },
  computed: {
    ...mapState(["orgInfo"])
  }
};
</script>
